body {
  margin: 0 !important;
  /* font-family: 'Alibaba'; */
}

.para-table {
  border-collapse: collapse;
  /* border-spacing: 15px 10px; */
  border-radius: 5px;
  width: 100%;
}


.para-td {
  border: 1px solid #ddd;
  padding: 10px;
}

.para-thead {
  font-weight: 400;
  height: 45px;
  background-color: rgb(250, 250, 250);
  color: black;
}


/* 
@font-face {
  font-family: Alibaba;
  src: url('./font/AlibabaPuHuiTi-3-45-Light.woff');
} */

.code {
  width: 100%;
  height: 100%;
}

.ArProgress {
  width: 100%;
  height: 3px;
  overflow: hidden;
  background-color: #AAAAAA;
}

.ArProgress>div {
  position: relative;
  height: 3px;
  transition: width .5s ease-in-out;
  background-color: green;
}

.ArLoadLine {
  width: 100%;
  height: 3px;
  overflow: hidden;
}

.ArLoadLine>div {
  position: relative;
  width: 10%;
  height: 3px;
  animation-name: ArLoadLine;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes ArLoadLine {
  from {
    left: -20%;
    background-color: palevioletred;
  }

  to {
    left: 100%;
    background-color: lawngreen;
  }
}

.hdpic {
  width: 100%;
  height: 350px;
  border-radius: 5px;
  z-index: -1;
  justify-content: center;
  object-fit: cover;
  filter: brightness(.6);
}

.lead {
  font-size: 1.1rem;
  font-weight: 100;
}

.title {
  font-size: 2.5rem;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.nav-blur {
  display: flex;
  align-items: center;
  padding-inline: 20px;
  position: fixed;
  z-index: 100;
  height: 60px;
  width: 100%;
  background: rgba(255, 255, 255, .6);
  backdrop-filter: saturate(180%) blur(10px);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
  box-shadow: 0px 1px 10px #999;
  transition: background-color .5s;
}

.nav-blur:hover {
  background: rgba(255, 255, 255, .9);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.GIIcons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.anchor {
  position: sticky;
  top: 50px;
  z-index: 10;
  backdrop-filter: blur(10px) saturate(1.8);
}

.spark {
  animation-name: spark;
  animation-duration: .5s;
}

@keyframes spark {
  0% {
    color: palevioletred;
  }

  100% {
    color: black;
  }
}